import { GetControlPoint } from "./control-point";
import { Contact } from "./common";
import { Address } from "./common";

export const REPORT_TO_SEND_STATUS = "reportToSend";
export interface GetSurvey {
  id: string;
  statut: "new" | "pending" | "validated";
  survey: Survey;
  type: SurveyType;
  pdc: GetControlPoint;
  date: string;
}

export interface Survey {
  id: string;
  idG2: string;
}

export interface SurveyType {
  type: string;
  label: string;
}

export interface adminData {
  numITV: string;
  datePrevue: Date | string;
  dateITV: Date | string;
  contrat: string;
  contratLib: string;
  typeControle: string;
  typeVisite: string;
  agentAffecte: string;
  ReferenceSPANCNumeroDossier: string;
  installateur: string;
  contratECA: string;
  sourceITV: string;
}

export interface controlPointData {
  idPDC: string;
  typePDC: number;
  typePropriete: number;
  nonDomestique: boolean;
  activiteNonDomestique: number;
  nbOccupant: number;
  adressePDC: Address;
  dernierEtatControle: string;
  dateDernierEtatControle: Date | string;
  dernierRapport: string;
}

export interface controlPointConformite {
  conformiteEvalue: string | null;
  conformite: string | null;
  complementConformite: string | null;
  commentaireConformite: string | null;
  conforme: string | null;
  nonconforme: string | null;
}
export interface contactSurveyData {
  clientITV: Contact;
  contactITV: Contact;
  contacts?: Contact[];
}
export interface permisConstruire {
  numeroPC: string;
  anneePC: number;
}
export interface parcelleData {
  parcelles: string[];
  permisConstruire: permisConstruire[];
  certificatUrbanisme: string;
  nombreAppartement: number;
  referenceExterneCollectivite: string;
}

export interface signatureData {
  contactSignature: string;
  juridique1: number;
  juridique2: number;
  juridique3: number;
  lieuSignature: string;
  dateSignature: Date | string;
  signature: string;
}

//@todo: Add rest data of survey
//@todo : make diff GetControlPoint & controlPointData
export interface SurveyData {
  donneeAdmin: adminData;
  infoPDC: controlPointData | GetControlPoint;
  infoContacts: contactSurveyData;
  conformitePDC: controlPointConformite;
}

//@todo : delete when detail page survery update
export interface SurveyRequestContext {
  id: string;
  label: string | null;
  type: string;
  createdAt: string;
  updatedAt: string;
}

// @todo : SurveyRequestContext : delete when detail page survery update
export interface SurveyRequest {
  id: string;
  data: SurveyData;
  context: SurveyRequestContext[] | null;
  nextPossibleStatuses: Status[];
  currentStatus: {
    status: Status;
    comment: string | null;
    updatedBy: string | null;
    createdBy: string | null;
    createdAt: string;
    updatedAt: string;
  };
  ecaTypeCode: string | null;
  workflow: workflow;
  controlPointId: string;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  formName: string;
}

export interface workflow {
  id: string;
  entries: { from: string; to: string }[];
  excludesStatuses: Status[];
  statusesHandledBySource: Status[];
}
export interface SurveyStatusHistory {
  status: Status;
  comment: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy: string | null;
  updatedBy: string | null;
}

export interface Status {
  id: string;
  label: string;
}

export interface FileData {
  id: string;
  operation: {
    id: string;
  };
  originalFileName: string;
  size: number;
  mimeType: string;
  target: string;
  image: boolean;
}
