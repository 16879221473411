import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SzButton, SzModal, SzTab } from "@suezenv/react-theme-components";
import { SzTabContent } from "@suezenv/react-theme-components/build/components/SzTab/SzTab";
import { SurveyRequest } from "../../../../services/types/survey";
import SurveyMailForm from "./commentTabs/mailModal/SurveyMailForm";
import SurveyMailGenerated from "./commentTabs/mailModal/SurveyMailGenerated";
import SurveyCommentHeader from "./commentTabs/SurveyCommentHeader";
import SurveyCommentTab from "./commentTabs/SurveyCommentTab";
import SurveyMailTab from "./commentTabs/SurveyMailTab";
import MailRepository from "../../../../services/api/repository/mail.repository";
import { Contact } from "../../../../services/types/common";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state-management";
import { ADMIN_SERVICE, hasService, READ_WRITE_VALIDATION_SERVICE } from "../../../../services/utils/accessManagment";
import { GenerateMailValues } from "../../../../services/types/mail.type";
import { AxiosError } from "axios";

export type CommentViewTab = "comment" | "mail";
type Tab = {
  title: string;
  eventKey: CommentViewTab;
  render: any;
  header: { title: string; subTitle: string; actionNode: React.ReactElement };
};

interface Props {
  survey: SurveyRequest;
}

const SurveyViewComment: React.FC<Props> = ({ survey }) => {
  const { t } = useTranslation(["survey.view"]);
  const [tab, setTab] = useState<CommentViewTab>("mail");
  const userState = useSelector((state: RootState) => state.userReducer);
  const hasEditService = hasService(userState.data?.services, survey ? survey.data.donneeAdmin.contratECA : null, [
    READ_WRITE_VALIDATION_SERVICE,
    ADMIN_SERVICE,
  ]);

  const contractId: string | undefined = useMemo(() => {
    if (survey.data && survey.data?.donneeAdmin) return survey.data?.donneeAdmin.contratECA;
  }, [userState]);
  const [reloadMail, setReloadMail] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{ show: boolean; generated: boolean }>({
    show: false,
    generated: false,
  });
  const contactsWithoutContactITV = useMemo<Contact[]>(() => {
    let contacts = [survey.data.infoContacts.clientITV];
    if (survey.data.infoContacts.contacts) {
      contacts = [...contacts, ...survey.data.infoContacts.contacts];
    }
    return contacts;
  }, [survey.data.infoContacts.clientITV, survey.data.infoContacts.contactITV, survey.data.infoContacts.contacts]);
  const tabs: Tab[] = [
    {
      title: t("details.mail.tabTitle"),
      eventKey: "mail",
      render: <SurveyMailTab survey={survey} reload={reloadMail} />,
      header: {
        title: t("details.mail.title"),
        subTitle: t("details.mail.subTitle"),
        actionNode: (
          <SzButton
            variant="tertiary"
            alignIcon="right"
            className="mr-3"
            icon="envelope"
            isDisabled={!hasEditService}
            onClick={() => {
              setModalData({ ...modalData, show: true });
            }}
          >
            {t("details.comment.mailAction")}
          </SzButton>
        ),
      },
    },
    {
      title: t("details.comment.tabTitle"),
      eventKey: "comment",
      render: <SurveyCommentTab />,
      header: {
        title: t("details.comment.title"),
        subTitle: t("details.comment.subTitle"),
        actionNode: (
          <SzButton variant="tertiary" className="mr-3" icon="messages-bubble-edit" alignIcon="right">
            {t("details.comment.commentAction")}
          </SzButton>
        ),
      },
    },
  ];

  const generateMail = (values: GenerateMailValues) => {
    if (survey.id && contractId) {
      MailRepository.createMail(survey.id, contractId, {
        comment: values.comment,
        contact: values.contact?.value!,
        type: values.type?.value!,
      })
        .then(() => {
          setModalData({ ...modalData, generated: true });
          setReloadMail(!reloadMail);
        })
        .catch((e: AxiosError<any>) => {
          console.error(e.response?.data.message);
        });
    }
  };

  return (
    <div>
      <SzTab id="survey-comment-view" activeKey={tab} selectTabHandle={(tab: CommentViewTab) => setTab(tab)}>
        {tabs.map((tab) => {
          return (
            <SzTabContent title={tab.title} eventKey={tab.eventKey} key={tab.eventKey}>
              <SurveyCommentHeader
                title={tab.header.title}
                subTitle={tab.header.subTitle}
                actionNode={tab.header.actionNode}
              />
              {tab.render}
            </SzTabContent>
          );
        })}
      </SzTab>
      <SzModal
        className="survey-mail-modal"
        title=""
        show={modalData.show}
        handleClose={() => setModalData({ generated: false, show: false })}
        size={"lg"}
        centered
      >
        {modalData.generated ? (
          <SurveyMailGenerated close={() => setModalData({ show: false, generated: false })} />
        ) : (
          contractId && (
            <SurveyMailForm contractId={contractId} generateMail={generateMail} contacts={contactsWithoutContactITV} survey={survey} />
          )
        )}
      </SzModal>
    </div>
  );
};
export default SurveyViewComment;
