import { AxiosResponse } from "axios";
import instanceRequest, { Request } from "../../Request";
import { FormType } from "../../types/form";

class FormRepository {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    getAll = (params: string = ''): Promise<AxiosResponse<FormType[]>> => {
        const url = params.length === 0 ? "/api/form" : "/api/form?"+params;
        return this.axiosService.get<FormType[]>(url, false, true);
    };
}

export default new FormRepository(instanceRequest);
