import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SzAlert, SzBox, SzButton, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import SzSelectWithLabel from "../../../../../../components/app/szSelectWithLabel";
import * as Yup from "yup";
import { Contact, contactTypesKey, Option } from "../../../../../../services/types/common";
import MailRepository from "../../../../../../services/api/repository/mail.repository";
import { GenerateMailValues, Template } from "../../../../../../services/types/mail.type";
import { useReferentials } from "../../../../../../state-management/referential-data/referential-data.hook";
import usePreventNullOrUndefined from "../../../../../../hooks/preventNullOrUndefined";
import { SurveyRequest } from "../../../../../../services/types/survey";

type ExtendedProps =
  | {
      contacts: Contact[];
      contractId: string;
      format?: "unique";
      survey?: SurveyRequest;
    }
  | {
      contractId: string;
      surveys: SurveyRequest[];
      format: "multiple";
      survey?: SurveyRequest;
    };
const INVALID_CONTACT_VALUE = "INVALID";
type Props = { generateMail: (values: GenerateMailValues) => void } & ExtendedProps;

const SurveyMailForm: React.FC<Props> = (props) => {
  const { t } = useTranslation(["survey.view"]);
  const invalidContactOption = {
    label: t("details.mail.modal.invalidContact"),
    value: INVALID_CONTACT_VALUE,
  };
  const { generateMail, format = "unique" } = props;

  const preventNullOrUndefined = usePreventNullOrUndefined("");
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [templates, setTemplates] = useState<Option[]>([]);
  const { getOption } = useReferentials();
  const isCompany = (contact: Contact) => {
    return contact.type === contactTypesKey.COMPANY;
  };
  useEffect(() => {
    if (props.contractId) {
      MailRepository.getTemplates(props.contractId).then(({ data }) => {

        // Only get contract templates that do not have a whitelist or that have the survey formName in their whitelist.
        const filteredData = data.filter(item => {
            if(item.formNames?.length === 0) {
                return item;
            } else if(props?.survey && item.formNames?.includes(props?.survey?.formName)) {
                return item;
            }
        });

        setTemplates(filteredData.map((template: Template) => ({ value: template.name, label: template.name })));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.format]);

  let contactSchema = Yup.object().shape({
    contact: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string()
        .required()
        .notOneOf([INVALID_CONTACT_VALUE], t("details.mail.modal.inValidContactErrorMessage")),
    }),
    type: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
  });

  if ("unique" === format) {
    contactSchema = contactSchema.concat(
      Yup.object().shape({
        contact: Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        }),
        comment: Yup.string(),
      }),
    );
  }

  const defaultValue: GenerateMailValues = {
    contact: null,
    type: null,
    comment: "",
  };

  let contacts: Contact[] = [];
  let surveysList = [];

  if (props.format !== "multiple") {
    contacts = props.contacts;
  } else {
    surveysList = props.surveys;
  }
  
  const getContactOption = (contact: Contact) => {
    let label = "";
    let value = "";
    if (contact.type === contactTypesKey.COMPANY) {
      if (!contact.raisonSociale && !contact.nom) {
        return invalidContactOption;
      }
      label = `${contact.raisonSociale ?? contact.nom} ${contact.numeroSiret ?? "-"}`;
      value = `${contact.raisonSociale ?? contact.nom}`;
    } else {
      if (!contact.nom) {
        return invalidContactOption;
      }
      const civility = getOption("civility", contact.civilite);
      label = `${preventNullOrUndefined(civility?.label)} ${contact.nom} ${contact.prenom ?? "-"}`;
      value = `${contact.nom}, ${contact.prenom ?? "-"}`;
    }
    return {
      label: `${label}`,
      value: value,
    };
  };
  const getContactOptions = (): Option[] | undefined => {
    return contacts?.map((contact: Contact) => getContactOption(contact));
  };

  return (
    <Formik validationSchema={contactSchema} onSubmit={generateMail} initialValues={defaultValue} enableReinitialize>
      {(formikProps) => {
        const { values, setFieldValue, isValid, dirty, errors } = formikProps;
        const errorsTypeless = errors as any;
        const { contact, type } = values;
        return (
          <Form className="m-auto w-100 m-4" onSubmit={formikProps.handleSubmit}>
            <div className="form-fields clearfix">
              <div className="row mb-2">
                <div className="col-auto pr-0">
                  <SzIcon variant="line" icon="envelope" />
                </div>
                <div className="col-auto pl-2">
                  <SzTypographie variant="body" weight="medium" className="mb-0">
                    {t("details.mail.modal.title")}
                  </SzTypographie>
                  <SzTypographie variant="caption" weight="light" className="mb-0">
                    {t("details.mail.modal.subTitle")}
                  </SzTypographie>
                </div>
              </div>
              <SzBox className="row py-2 p-4  justify-content-center bg-white" tag="div">
                <div className="col-7">
                  {"multiple" === format && (
                    <div className="row pb-1 mb-4 text-center">
                      <SzTypographie color="disabled" variant="text" weight="light" className="mb-0">
                        {t("details.mail.modal.survey_selectionned", { data: surveysList.length })}
                      </SzTypographie>
                    </div>
                  )}
                  {"unique" === format && (
                    <div className="row pb-1 mb-4">
                      <div className="col-auto pr-0">
                        <SzIcon variant="line" icon="business-contract-handshake-sign" />
                      </div>
                      <div className="col-auto pl-1">
                        <SzTypographie variant="body" weight="medium" className="mb-0">
                          {t("details.mail.modal.contract")}
                        </SzTypographie>
                        <SzTypographie variant="caption" weight="light" className="mb-0">
                          {props.format !== "multiple" && props.contractId}
                        </SzTypographie>
                      </div>
                    </div>
                  )}

                  {"unique" === format && (
                    <div className="row pb-2">
                      <div className="col">
                        {errorsTypeless.hasOwnProperty("contact") && errorsTypeless.contact?.value && (
                          <SzAlert variant="danger">{errorsTypeless.contact?.value}</SzAlert>
                        )}
                        <SzSelectWithLabel
                          error={errors.hasOwnProperty("contact")}
                          name="contact"
                          placeholder={t("details.mail.modal.chooseContact.placeholder")}
                          label={t("details.mail.modal.chooseContact.label")}
                          value={contact}
                          options={getContactOptions()!}
                          onChange={(option: Option) => {
                            setFieldValue("contact", option);
                            const selected = option
                              ? contacts.find(
                                  (contactItem: Contact) => option.value === getContactOption(contactItem).value,
                                )
                              : null;
                            setSelectedContact(selected ? selected : null);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="row pb-2">
                    <div className="col">
                      <SzSelectWithLabel
                        name="type"
                        placeholder={t("details.mail.modal.mailType.placeholder")}
                        label={t("details.mail.modal.mailType.label")}
                        value={type}
                        options={templates}
                        onChange={(option: Option) => {
                          setFieldValue("type", option);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {selectedContact && (
                  <div className="col-5">
                    <div className="row mb-3">
                      <div className="col">
                        <SzTypographie variant="body" weight="medium" className="mb-0">
                          {t("details.mail.modal.contactDetailsTitle")}
                        </SzTypographie>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        {!isCompany(selectedContact) && (
                          <SzTypographie variant="body" weight="light" className="mb-0">
                            {t("details.mail.modal.firstName", {
                              data: selectedContact.prenom,
                            })}
                          </SzTypographie>
                        )}
                        {!isCompany(selectedContact) && (
                          <SzTypographie variant="body" weight="light" className="mb-0">
                            {t("details.mail.modal.lastName", {
                              data: selectedContact.nom,
                            })}
                          </SzTypographie>
                        )}
                        {isCompany(selectedContact) && (
                          <SzTypographie variant="body" weight="light" className="mb-0">
                            {t("details.mail.modal.siret", {
                              data: selectedContact.numeroSiret,
                            })}
                          </SzTypographie>
                        )}
                        {isCompany(selectedContact) && (
                          <SzTypographie variant="body" weight="light" className="mb-0">
                            {t("details.mail.modal.company", {
                              data: selectedContact.raisonSociale,
                            })}
                          </SzTypographie>
                        )}
                        {selectedContact.adresse && selectedContact.adresse.addresseComplete && (
                          <SzTypographie variant="body" weight="light" className="mb-0">
                            {t("details.mail.modal.fullAddress", {
                              data: `${selectedContact.adresse.addresseComplete}`,
                            })}
                          </SzTypographie>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </SzBox>
              <div className="row justify-content-around mt-3">
                <div className="col-auto">
                  <SzButton isDisabled={!(isValid && dirty)} icon="download-bottom" type="submit">
                    {t("details.mail.modal.submit")}
                  </SzButton>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SurveyMailForm;
